import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '@/components/Layout';
import { PageContainer, PageHeader, Section } from '@/components/elements';
import Seo from '@/components/Seo';
import CtaIconLinks from '@/components/CTA/CtaIconLinks';
import TermsIcon from '@/assets/svg/icon-terms.svg';
import PrivacyIcon from '@/assets/svg/icon-privacy.svg';
import KeyPoints from '@/components/KeyPoints';
import IconKeyPointSafeSecure from '@/assets/svg/icon-safe-secure-white.svg';
import IconKeyPointUptime from '@/assets/svg/icon-uptime-white.svg';
import IconKeyPointOptimized from '@/assets/svg/icon-server-opitimized-white.svg';
import IconKeyPointSupport from '@/assets/svg/icon-support-white.svg';
import ContentSection from '@/components/ContentSection';

const AboutPage: React.FC<PageProps> = () => (
  <Layout>
    <Seo title="About Us | MyServerJapan" description="" lang="en" />
    <PageContainer>
      <PageHeader
        title="ABOUT US"
        subtitle="MyServerJapan is a web site hosting service based in central Japan. We provide fast, secure and reliable servers in English that makes running your business stress free!"
        bgImage={
          <StaticImage
            src="../assets/images/about-header-bg.png"
            alt=""
            quality={100}
            layout={`fixed`}
            height={300}
          />
        }
        bgColor={`#db0000`}
      />
      <ContentSection title="About the Company">
        <p>
          In 2016, MyServerJapan was established as Japan-Server to provide our
          clients with in-house website hosting, and at the time bring an easier
          option than dealing with outdated server companies in Japan. With this
          mission to find solutions to the language barriers and help Japanese
          and foreign businesses and individuals get online, the company has
          expanded to provide a wider range of services to all, and is now a
          subsidiary of our mother company Red Crane LLC.
        </p>
        <p>
          With our knowledge, skills and resources, we hope that we can bring
          your digital vision to life and provide you with fast, secure and
          reliable servers in English & Japanese that makes running your
          business stress free from Japan!
        </p>
      </ContentSection>
      <CtaIconLinks
        bgColor={`primaryLighter`}
        title="Our Services"
        description="We provide a wide range of professional solutions that can help your businesses needs in Japan and from overseas."
        items={[
          {
            id: `domain`,
            title: `Domain Management`,
            description: `Registering a domain and getting it setup can be quite daunting. If you need it setup and managed, we can provide the service to have it done and no stress for you!`,
            icon: <TermsIcon />,
            url: `/`,
          },
          {
            id: `hosting`,
            title: `Website & Email Hosting`,
            icon: <TermsIcon />,
            url: `/hosting`,
          },
          {
            id: `custom`,
            title: `Custom Server Solutions`,
            icon: <PrivacyIcon />,
            url: `/`,
          },
        ]}
        //  fullwidth
      />
      <KeyPoints
        title={`Our Servers`}
        description={
          <p>
            We utilize top-of-the-line servers in Japan and other parts of the
            world to power your needs. Each server is optimized for speed and
            limited to how many clients can operate on each. This powerful
            combination of resources, performance enhancements, and limited
            occupancy means faster page load times that help improve your SEO
            and your visitors experience!
          </p>
        }
        //  iconColor={pageColor}
        items={[
          {
            title: `Safe & Secure`,
            text: `Our servers are constantly updated to make sure they are safe and secure!`,
            icon: <IconKeyPointSafeSecure />,
          },
          {
            title: `99.9% Up time`,
            text: `We are proud to say that our servers are up 99.9% of the time.`,
            icon: <IconKeyPointUptime />,
          },
          {
            title: `Optimized for Japan`,
            text: `Get the best speed and performance for your website in Japan and Asia.`,
            icon: <IconKeyPointOptimized />,
          },
          {
            title: `English Support`,
            text: `All are systems are in English & Japanese as standard.`,
            icon: <IconKeyPointSupport />,
          },
        ]}
      />
      <ContentSection title="About the Red Crane" bgColor={`primaryLighter`}>
        <p>
          MyServerJapan is owned and operated by the website / app development
          and internet solutions company, Red Crane LLC, and headquartered in
          central Japan. Red Crane LLC provides various solutions within its
          ecosystem.
        </p>
      </ContentSection>

      <CtaIconLinks
        bgColor={`none`}
        title="Legal Information"
        //  description="Take a look at our hosting plans!"
        items={[
          {
            id: `terms`,
            title: `Terms of Use`,
            icon: <TermsIcon />,
            url: `/legal/terms-of-use`,
          },
          {
            id: `privacy`,
            title: `Privacy Policy`,
            icon: <PrivacyIcon />,
            url: `/legal/privacy-policy`,
          },
        ]}
        //  fullwidth
      />
    </PageContainer>
  </Layout>
);

export default AboutPage;
